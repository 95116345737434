<template>
  <div class="page-about-container">
    <div class="about__title">
      <div class="title__hero-show">
        <div class="title__group">
          <p class="rellax" data-rellax-speed="13">F</p>
          <p class="rellax" data-rellax-speed="9">.</p>
          <p class="rellax" data-rellax-speed="16">G</p>
        </div>
        <div class="title__group">
          <p class="rellax" data-rellax-speed="7">S</p>
          <p class="rellax" data-rellax-speed="12">t</p>
          <p class="rellax" data-rellax-speed="3">u</p>
          <p class="rellax" data-rellax-speed="11">d</p>
          <p class="rellax" data-rellax-speed="6">i</p>
          <p class="rellax" data-rellax-speed="20">o</p>
        </div>
      </div>
    </div>
    <div
      class="about__bg in-view preload"
      data-effect="scale"
      :data-src="
        aboutTop
          ? $isMobile
            ? aboutTop.value_text.mobile
            : aboutTop.value_text.pc
          : ''
      "
    >
      <div
        class="bg__inner"
        data-rellax-speed="3"
        :class="{
          rellax: !$isMobile
        }"
      >
        <img
          :src="
            aboutTop
              ? $isMobile && aboutTop
                ? aboutTop.value_text.mobile
                : aboutTop.value_text.pc
              : ''
          "
          alt=""
        />
      </div>
    </div>
    <div
      class="about__content"
      :class="{
        'in-view': !$isMobile
      }"
      data-effect="bg"
      :data-color="$isMobile ? '#fff' : '#e6e1dc'"
    >
      <div class="content__wrapper">
        <div class="content__title scrollObj">
          <p>
            Professional Design Agency
          </p>
          <p>
            Tailor-made professional services for all areas of soft<br />
            design requirements
          </p>
        </div>
        <div class="content__main">
          <div
            class="main__pics scrollObj"
            :class="{
              'in-view': $isMobile
            }"
            data-effect="bg"
            :data-color="$isMobile ? '#e6e1dc' : '#e6e1dc'"
          >
            <div class="pics__item">
              <img
                class="preload"
                :data-src="aboutBottom ? aboutBottom.value_text : ''"
                :src="aboutBottom ? aboutBottom.value_text : ''"
                alt=""
              />
            </div>
          </div>
          <div class="main__text">
            <div class="text__content scrollObj">
              <p>
                F.G
                是一个崇尚不局囿于风格，有创造性融合的设计品牌，创建于2005年，致力于为地产行业提供软装设计、居家单品研发及制造的专业服务商。
              </p>
              <p>
                F. G is a creative and integrated design brand, which is not
                limited to style. Founded in 2005, G is dedicated to providing
                professional services for the real estate industry, such as soft
                decoration design, home product development and manufacturing.
              </p>
              <p>
                一直以来， F.G
                秉持着不断探索的设计理念，以人为本提升空间的体验感，旨在为大家带来欢乐幸福的一刻。作为服务于地产行业的设计品牌，F.G
                见证软装行业发展十几年，从无到有，参与行业标准建立，立足上海，项目遍及中国20个省，50个城市，合作伙伴均为国内领先开发商。我们深谙设计工作在销售助力、功能界定和美学情怀方面的重要性，致力于营销理念的有效传达，为其提供富有远见的策略性服务。
              </p>
              <p>
                All along, f.g. adheres to the design concept of continuous
                exploration, people-oriented, to enhance the sense of experience
                of space, aiming to bring you a happy moment. As a design brand
                serving the real estate industry, f.g. has witnessed the
                development of soft decoration industry for more than ten years.
                From scratch, f.g. has participated in the establishment of
                industry standards. Based in Shanghai, its projects cover 20
                provinces and 50 cities in China, and its partners are leading
                domestic developers. We are well aware of the importance of
                design work in sales assistance, function definition and
                aesthetic feelings, and are committed to the effective
                communication of marketing concepts and provide visionary
                strategic services for them.
              </p>
              <p>
                2019年创立CHICFORGO品牌，
                从「简单-雅致-永恒」的设计与服务理念出发，旨在为家庭的每个房间创造漂亮优雅的一角。
              </p>
              <p>
                In 2019, chicforgo brand was founded, starting from the design
                and service concept of "simplicity elegance eternity", aiming to
                create a beautiful and elegant corner for every room in the
                family.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about__team-container">
      <div class="team-container__wrapper">
        <div class="team__title scrollObj">
          <p>team</p>
        </div>
        <div class="team__summary-container">
          <div class="summary__grid">
            <div class="grid__text scrollObj">
              <p class="en">
                We are a design company from Shanghai. Our design centers are
                located in Shanghai, China and Auckland, New Zealand.
              </p>
              <p v-if="!$isMobile">
                <strong
                  >我们是一家来自上海的设计公司，<br />
                  我们的设计中心分别位于中国上海与新西兰的奥克兰。</strong
                >
              </p>
            </div>
            <div class="grid__text scrollObj" v-if="!$isMobile">
              <p>
                我们旨在为家庭的每个房间创造漂亮优雅的一角。<br />
                我们相信我们周围的事物会给我们带来温暖与快乐。<br />
                我们的每一件创作，都坚持保有其功能之外的美，<br />
                确保具有装饰性、实用性。<br />
                从而希望能为大家带来欢乐幸福的一刻。<br />
                我们与我们的供应商长期以来一直保持着愉快的合作，<br />
                以确保我们产品品质的稳定性。<br />
                高端品质与合理价格的优质服务一直是我们不懈追求的目标。
              </p>
            </div>
          </div>
          <div class="summary__grid">
            <img class="scrollObj" src="@/assets/images/team.jpg" alt="" />
            <div class="grid__text scrollObj" v-if="$isMobile">
              <p>
                <strong
                  >我们是一家来自上海的设计公司，<br />
                  我们的设计中心分别位于中国上海与新西兰的奥克兰。</strong
                >
              </p>
              <p>
                我们旨在为家庭的每个房间创造漂亮优雅的一角。<br />
                我们相信我们周围的事物会给我们带来温暖与快乐。<br />
                我们的每一件创作，都坚持保有其功能之外的美，<br />
                确保具有装饰性、实用性。<br />
                从而希望能为大家带来欢乐幸福的一刻。<br />
                我们与我们的供应商长期以来一直保持着愉快的合作，<br />
                以确保我们产品品质的稳定性。<br />
                高端品质与合理价格的优质服务一直是我们不懈追求的目标。
              </p>
            </div>
          </div>
        </div>
        <div
          class="team__main-body"
          :class="{
            'in-view': !$isMobile
          }"
          data-effect="bg"
          data-color="#F4F4F4"
          ref="mouseMoveContainer"
          v-if="teams.length > 0"
        >
          <template v-if="teams.length > 3 || $isMobile">
            <swiper
              class="team__swiper-wrapper"
              :options="swiperOptions"
              ref="teamSwiper"
              style="margin-left:0"
            >
              <swiper-slide
                class="team__swiper-slide big"
                v-for="(item, index) in teams"
                :key="index"
              >
                <div class="slide__wrapper scrollObj">
                  <div class="slide__cover">
                    <img :src="item.cover" alt="" />
                    <div class="hover__text" v-if="item.title && item.summary">
                      <div class="text__inner">
                        <div class="text__grid">
                          <p v-html="formatHtml(item.summary)"></p>
                        </div>
                        <div class="text__grid">
                          <div class="grid__inner">
                            <p>{{ item.title.en }}</p>
                            <p>{{ item.title.cn }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slide__text">
                    <p>{{ item.name.en }}</p>
                    <p>{{ item.name.cn }}</p>
                    <i class="line"></i>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </template>
          <template
            v-else-if="!$isMobile && teams.length > 0 && teams.length <= 3"
          >
            <div class="team__swiper-wrapper common-line" ref="teamSwiper">
              <div
                class="team__swiper-slide"
                v-for="(item, index) in teams"
                :key="index"
                :class="{
                  small: index % 2 === 0,
                  big: index % 2 !== 0
                }"
              >
                <div class="slide__wrapper scrollObj">
                  <div class="slide__cover">
                    <img :src="item.cover" alt="" />
                    <div class="hover__text" v-if="item.title && item.summary">
                      <div class="text__inner">
                        <div class="text__grid">
                          <p v-html="formatHtml(item.summary)"></p>
                        </div>
                        <div class="text__grid">
                          <div class="grid__inner">
                            <p>{{ item.title.en }}</p>
                            <p>{{ item.title.cn }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slide__text">
                    <p>{{ item.name.en }}</p>
                    <p>{{ item.name.cn }}</p>
                    <i class="line"></i>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="team-swiper-summary scrollObj" v-if="$isMobile">
            <div class="team-swiper__summary-inner" ref="summaryParent">
              <div
                class="summary-item"
                v-for="(team, ti) in teams"
                :key="ti"
                ref="summaryItems"
                :class="{
                  actived: Number(mobileSwiperActiveIndex) === ti
                }"
              >
                <div class="item__name">
                  <p>
                    <span class="en">{{ team.name.en }}</span
                    ><span class="cn">{{ team.name.cn }}</span>
                  </p>
                </div>
                <div class="item__contents">
                  <div class="item__summary" v-if="team.summary">
                    <p v-html="formatHtml(team.summary)"></p>
                  </div>
                  <div class="item__title" v-if="team.title">
                    <p class="en">{{ team.title.en }}</p>
                    <p class="cn">{{ team.title.cn }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="team-swiper-pagination">
            <template v-if="teams.length > 0 && teams.length < 4">
              <span>01</span>/<span>0{{ teams.length }}</span>
            </template>
          </div>
          <!-- <div class="team-follow-container" ref="follower">
            <div class="follow__main"></div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="about__news-container" v-if="false">
      <div class="news-container__wrapper">
        <div class="news__title scrollObj">
          <p>news</p>
        </div>
        <div class="news__main-body">
          <swiper
            class="news__swiper-wrapper"
            :options="newsSwiperOptions"
            ref="newSwiper"
          >
            <swiper-slide
              class="news__swiper-slide"
              v-for="(item, index) in news"
              :key="index"
            >
              <div class="news__swiper-wrapper scrollObj">
                <div class="slide__cover">
                  <img :src="item.cover" alt="" />
                </div>
                <div class="slide__text">
                  <div class="news__publish">
                    {{ item.publish }}
                  </div>
                  <div class="news__title">
                    {{ item.title }}
                  </div>
                  <div class="news__content">
                    {{ item.summary }}
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { imgPreload } from "@/assets/utils/common";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "page-about",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    const that = this;
    const space = that.$isMobile
      ? (47 * window.innerWidth) / 750
      : (475 * window.innerWidth) / 1920;
    const nspace = (60 * window.innerWidth) / 1920;
    const sos = that.$isMobile
      ? {
          spaceBetween: 0,
          loop: true,
          loopedSlides: 2,
          slidesPerView: "auto",
          pagination: {
            el: ".team-swiper-pagination",
            type: "fraction",
            formatFractionCurrent(number) {
              return that.formatNumber(number);
            },
            formatFractionTotal(number) {
              return that.formatNumber(number);
            }
          },
          on: {
            slideChangeTransitionStart() {
              that.mobileSwiperActiveIndex = this.realIndex;
            }
          }
        }
      : {
          autoplay: {
            disableOnInteraction: false
          },
          spaceBetween: space,
          watchSlidesProgress: true,
          slidesPerView: "auto",
          centeredSlides: true,
          loop: true,
          loopedSlides: 3,
          pagination: {
            el: ".team-swiper-pagination",
            type: "fraction",
            formatFractionCurrent(number) {
              return that.formatNumber(number);
            },
            formatFractionTotal(number) {
              return that.formatNumber(number);
            }
          },
          on: {
            progress: function(progress) {
              console.log(progress);
              that.$nextTick(() => {
                const slides = that.$refs.teamSwiper.$swiper.slides;
                let modify, translate, scale, zIndex;
                for (let i = 0; i < slides.length; i++) {
                  var slide = slides.eq(i);
                  var slideProgress = slides[i].progress;
                  modify = 1;
                  if (Math.abs(slideProgress) > 1) {
                    modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
                  }
                  translate = slideProgress * modify * 260 + "px";
                  scale = 1 - Math.abs(slideProgress) / 5;
                  zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                  slide.transform(
                    "translateX(" + translate + ") scale(" + scale + ")"
                  );
                  slide.css("zIndex", zIndex);
                  slide.css("opacity", 1);
                  if (Math.abs(slideProgress) > 3) {
                    slide.css("opacity", 0);
                  }
                }
              });
            },
            setTransition: function(transition) {
              that.$nextTick(() => {
                const slides = that.$refs.teamSwiper.$swiper.slides;
                for (var i = 0; i < this.slides.length; i++) {
                  var slide = slides.eq(i);
                  slide.transition(transition);
                }
              });
            }
          }
        };
    return {
      teams: [],
      swiperOptions: sos,
      news: [],
      newsSwiperOptions: {
        slidesPerView: 4,
        spaceBetween: nspace
      },
      mouseSX: 0,
      mobileSwiperActiveIndex: 0
    };
  },
  computed: {
    ...mapGetters(["aboutTop", "aboutBottom"])
  },
  watch: {
    $route: {
      handler: "init",
      immediate: true
    },
    mobileSwiperActiveIndex() {
      this.setCurrentSlide();
    }
  },
  mounted() {
    const that = this;
    that.$nextTick(() => {});
  },
  methods: {
    ...mapMutations(["PAGE_LOADING", "PAGE_LOADED"]),
    init() {
      const that = this;
      that.PAGE_LOADING();
      Promise.all([that.getTeam(), that.getNews(), imgPreload()]).then(res => {
        const teams = res["0"].data.lists;
        const news = res["1"].data;
        that.teams = teams;
        that.news = news;
        that.PAGE_LOADED();
        // if (teams.length > 3) {
        //   that.$nextTick(() => {
        //     that.mouseMoveCustom();
        //   });
        // }
        that.$nextTick(() => {
          if (that.$isMobile) {
            that.$refs.summaryItems.forEach(item => {
              const itemHeight = item.getBoundingClientRect().height;
              item.dataset.height = itemHeight;
            });
            that.setCurrentSlide();
          }
        });
      });
    },
    formatNumber(number) {
      return number < 10 ? `0${number}` : number;
    },
    mouseMoveCustom() {
      const that = this;
      const container = that.$refs.mouseMoveContainer;
      const follower = that.$refs.follower;
      const teamSwiper = that.$refs.teamSwiper;
      const followerWidth = follower.getBoundingClientRect().width;
      container.onmousemove = ev => {
        const { top } = container.getBoundingClientRect();
        follower.style.cssText = `top:${ev.clientY -
          top -
          followerWidth / 2}px;left:${ev.clientX - followerWidth / 2}px;`;
      };
      container.onmouseenter = () => {
        follower.classList.add("show");
      };
      container.onmouseleave = () => {
        follower.classList.remove("show");
      };
      container.onmousedown = ev => {
        follower.classList.add("active");
        that.mouseSX = ev.screenX;
      };
      container.onmouseup = ev => {
        follower.classList.remove("active");
        if (ev.screenX > that.mouseSX) {
          teamSwiper.$swiper.slidePrev();
        } else if (ev.screenX < that.mouseSX) {
          teamSwiper.$swiper.slideNext();
        }
      };
    },
    getTeam() {
      return this.$request.get("/api/team/list");
    },
    getNews() {
      return this.$request.get("/api/news/list");
    },
    formatHtml(text) {
      return text.replaceAll("\r\n", "<br/>");
    },
    setCurrentSlide() {
      const current = this.$refs.summaryItems[this.mobileSwiperActiveIndex];
      this.$refs.summaryParent.style.height = `${current.dataset.height}px`;
    }
  }
};
</script>

<style></style>
